@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  background-color: #000 !important;
}

.App {
  text-align: center;
  justify-content: center;
  /* min-height: 100vh; */
  /* height: 100vh; */
  background-size: 100% 100%;
  color: white;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
}

a {
  font-family: "Poppins", sans-serif;
  text-decoration: none !important;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.textonaranja {
  font-family: "Poppins", sans-serif;
  color: #f29100 !important;
  font-size: 25px;
  font-weight: bold;
}

.footer {
  width: 100%;
  padding: 0px 20px;
}

.title--3 {
  font-family: "Poppins", serif;
  font-weight: bold;
  font-size: 3rem;
  line-height: 1em;
  text-shadow: 3px 3px 0 rgb(29 29 27 / 10%);
  text-transform: uppercase;
  margin: 0 0 20px 0;
}

.botonunirme {
  margin-top: 15px;
  padding: 9px 20px;
  /* min-width: 340px;
  max-width: 100%; */
  background: #fff;
  border-radius: 1em;
  /* background-hover: var(--background); */
  color: #1d1d1b !important;
  text-decoration: none;
  /* color-hover: var(--color); */
  border-color: #fff;
}

.textblanco {
  font-family: "Poppins", sans-serif;
  color: #fff !important;
}

.textoAgregador {
  font-family: "Poppins", sans-serif;
}

.botonunirme:hover {
  /* color: #0a58ca; */
  color: #fff !important;
  background: #f29100;
  /* border-color: #fff; */
  border: 2px solid #fff;
}

.icono {
  color: #fff !important;
  cursor: pointer;
}

.icono:hover {
  color: #f29100 !important;
  cursor: pointer;
}

.grow {
  transition: all 0.2s ease-in-out;
  color: #fff !important;
  cursor: pointer;
}

.grow:hover {
  transform: scale(1.1);
}

.mail-link {
  color: #0d6efd !important;
}